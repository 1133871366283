import React from 'react'
import Blank from '../../layouts/blank'
import { Typography } from 'antd'

export default function Error404() {
  return (
    <Blank>
        <Typography.Title style={{margin: 'auto'}} level={1} type='secondary'>Opps! 404 page not found!</Typography.Title>
    </Blank>
  )
}
