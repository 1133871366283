import { createContext, useContext, useState } from 'react';

const InitContext = createContext([null, (user) => {}]);

export default function UserContext() {
  const setting = useContext(InitContext);

  return setting;
}

export const UserProvider = ({ children, dataDefault = null }) => {
  const user = useState(dataDefault);

  return <InitContext.Provider value={user}>{children}</InitContext.Provider>;
};
