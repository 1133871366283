import axios from "axios";
import Cookies from "js-cookie";
import { NAME_ACCESS_TOKEN } from "../config/constant";

const config = {
    baseURL: process.env.REACT_APP_BASE_URL_API + '/api',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
};

const accessToken = Cookies.get(NAME_ACCESS_TOKEN);
if(accessToken){
    config.headers['Authorization'] = `Bearer ${accessToken}`;
}

export default axios.create(config)