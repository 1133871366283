import { createBrowserRouter } from "react-router-dom";
import Login from "./pages/login";
import "antd/dist/reset.css";
import Register from "./pages/register";
import Dashboard from "./pages/dashboard";
import Group from "./pages/group";
import Contact from "./pages/contact";
import ContactDetail from "./pages/contact/[id]";
import Error404 from "./pages/error/404";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/group",
    element: <Group />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/contact/:id",
    element: <ContactDetail />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
]);

export default router;
