import { Form, Input, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";

const defaultValue = {
  name: "",
};

export default function ModalFormGroup({
  open,
  titleModal,
  apiSubmit,
  group,
  onOk,
  onCancel,
}) {
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const changeForm = () => {};

  useEffect(() => {
    if (group && group.id) {
      form.setFieldsValue(group);
    }
  }, [group]);

  const submitForm = async () => {
    setErrors({});
    setLoading(true);
    const formData = form.getFieldsValue();
    try {
      const {
        data: { data },
      } = await (group?.id
        ? apiSubmit(group.id, formData)
        : apiSubmit(formData));
      onOk(data);
      form.setFieldsValue(defaultValue);
    } catch (error) {
      if (error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        Modal.confirm({
          title: error.response?.data?.message ?? "Something error.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const cancelModal = () => {
    onCancel();
    setErrors({});
    form.setFieldsValue(defaultValue);
  };

  return (
    <Modal
      title={titleModal}
      centered
      open={open}
      width={800}
      okText={"Submit"}
      onOk={submitForm}
      onCancel={cancelModal}
    >
      <Form
        name={`group-${group?.id ?? 0}`}
        form={form}
        layout="vertical"
        initialValues={defaultValue}
        autoComplete="off"
        onChange={changeForm}
      >
        <Form.Item
          label="Name"
          name="name"
          validateStatus={errors.name ? "error" : ""}
          extra={
            errors.name && (
              <Typography.Text type="danger">{errors.name}</Typography.Text>
            )
          }
        >
          <Input disabled={loading} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
