import { Layout } from 'antd'
import React from 'react'

export default function Blank({children}) {
  return (
    <Layout style={{minHeight: '100vh'}}>
        {children}
    </Layout>
  )
}
