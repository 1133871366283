import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  ContactsOutlined,
  SearchOutlined,
  ShareAltOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";

function SearchContact({
  style,
  groups,
  onSearch,
  onImport,
  onExport,
  onToggleShare,
  allowShare,
}) {
  const [form] = Form.useForm();
  const [submitImport, setSubmitImport] = useState(false);

  const onFinish = (values) => {
    onSearch(values);
  };

  const [modal, contextHolder] = Modal.useModal();

  const [fileUpload, setFileUpload] = useState(null);

  const changeFileUpload = ({ file }) => {
    setFileUpload(file);
  };

  useEffect(() => {
    if (!submitImport) return;
    onImport(fileUpload);
    setSubmitImport(false);
  }, [submitImport]);

  const clickImport = useCallback(() => {
    modal.confirm({
      icon: <ContactsOutlined />,
      title: "Import Contact",
      okText: "Upload",
      onOk() {
        setSubmitImport(true);
      },
      onCancel() {
        setFileUpload(null);
      },
      content: (
        <>
          <div style={{ marginBottom: "12px" }}>
            <a
              target="_blank"
              style={{ textDecoration: "underline" }}
              download={true}
              href="/format_import.xlsx"
            >
              Download format file
            </a>
          </div>
          <Upload
            name="logo"
            isImageUrl={false}
            multiple={false}
            accept=".xlsx,.xls"
            maxCount={1}
            beforeUpload={() => false}
            onChange={changeFileUpload}
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </>
      ),
    });
  }, [fileUpload]);

  return (
    <Form
      style={style}
      form={form}
      name="horizontal_login"
      defaultValue={{ groupId: null }}
      layout="inline"
      onFinish={onFinish}
    >
      <Form.Item name="search">
        <Input placeholder="Search Text..." />
      </Form.Item>
      <Form.Item name="groupId" style={{ width: 170 }}>
        <Select placeholder="Group" removeIcon={<UserOutlined />}>
          <Select.Option value={null}>Choose Group</Select.Option>
          {groups.map((i, idx) => (
            <Select.Option key={idx} value={i.id}>
              {i.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
            Search
          </Button>
          <Button
            type="primary"
            htmlType="button"
            onClick={onToggleShare}
            icon={<ShareAltOutlined />}
          >
            {allowShare ? "Disallow" : "Allow"} Share
          </Button>
          <Button
            type="default"
            htmlType="button"
            icon={<CloudDownloadOutlined />}
            onClick={onExport}
          >
            Export
          </Button>
          <Button
            type="default"
            htmlType="button"
            icon={<CloudUploadOutlined />}
            onClick={clickImport}
          >
            Import
          </Button>
        </Space>
      </Form.Item>
      {contextHolder}
    </Form>
  );
}

export default SearchContact;
