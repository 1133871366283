import React, { useState } from "react";
import { Button, Form, Input, Typography } from "antd";
import Card from "antd/es/card/Card";
import authApi from "../../libs/api/auth";
import Cookies from "js-cookie";
import { NAME_ACCESS_TOKEN, NAME_REDIRECT_URL_AFTER_LOGIN } from "../../libs/config/constant";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import GuestLayout from "../../layouts/guest/index";
import UserContext from "../../context/user.context";

const initialValuesForm = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
};

const Login = () => {
  const navigate = useNavigate();

  const [form] = useForm();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [, setUser] = UserContext();

  const onFinish = async (values) => {
    setLoading(true);
    setErrors({});
    try {
      const {
        data: {
          data: { token, user },
        },
      } = await authApi.register(values);
      Cookies.set(NAME_ACCESS_TOKEN, token);
      setUser(user);
      const nextUrl = Cookies.get(NAME_REDIRECT_URL_AFTER_LOGIN);
      if (nextUrl) {
        Cookies.remove(NAME_REDIRECT_URL_AFTER_LOGIN);
        window.location.href = nextUrl;
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      if (error.response?.data?.errors) {
        setErrors(error.response?.data?.errors);
      } else {
        setErrors({ email: error.response?.data?.message ?? "Server Error" });
      }
    } finally {
      setLoading(false);
    }
  };

  const changeForm = (e) => {
    const { name, value } = e.target;
    form.setFieldsValue({ [name]: value });
  };

  return (
    <GuestLayout>
      <Card style={{ width: 500, margin: "auto" }}>
        <Typography.Title level={2} content="zxczx">
          Register
        </Typography.Title>
        <Form
          name="basic"
          form={form}
          layout="vertical"
          initialValues={initialValuesForm}
          onFinish={onFinish}
          autoComplete="off"
          onChange={changeForm}
        >
          <Form.Item
            label="Name"
            name="name"
            validateStatus={errors.name ? "error" : ""}
            extra={
              errors.name && (
                <Typography.Text type="danger">{errors.name}</Typography.Text>
              )
            }
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            validateStatus={errors.email ? "error" : ""}
            extra={
              errors.email && (
                <Typography.Text type="danger">{errors.email}</Typography.Text>
              )
            }
          >
            <Input disabled={loading} />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            validateStatus={errors.password ? "error" : ""}
            extra={
              errors.password && (
                <Typography.Text type="danger">
                  {errors.password}
                </Typography.Text>
              )
            }
          >
            <Input.Password disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password_confirmation"
            validateStatus={errors.password_confirmation ? "error" : ""}
            extra={
              errors.password_confirmation && (
                <Typography.Text type="danger">
                  {errors.password_confirmation}
                </Typography.Text>
              )
            }
          >
            <Input.Password disabled={loading} />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
              <Typography.Link
                style={{ textDecoration: "underline" }}
                onClick={() => navigate("/login")}
              >
                Back to Login
              </Typography.Link>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </GuestLayout>
  );
};

export default Login;
