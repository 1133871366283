import React, { useCallback, useEffect, useState } from "react";
import AuthLayout from "../../layouts/auth/index";
import { Button, Modal, Space, Table, Tooltip, Typography } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  LinkOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ModalFormContact from "../../components/modal/contact/modal-form";
import contactApi from "../../libs/api/contact";
import groupApi from "../../libs/api/group";
import SearchContact from "../../components/form/search-contact";
import { utils, writeFileXLSX } from "xlsx";
import moment from "moment";
import authApi from "../../libs/api/auth";
import ModalShareUser from "../../components/modal/contact/share";

const onExport = async () => {
  const {
    data: { data },
  } = await contactApi.export();
  const newData = data.map((i) => ({
    ID: i.id,
    Name: i.name,
    Email: i.email,
    "Phone Number": i.phoneNumber,
    Group: i.group?.name,
    "Created At": moment(i.createdAt).format("YYYY-MM-DD hh:mm:ss"),
  }));
  const worksheet = utils.json_to_sheet(newData);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, worksheet, "Data");
  writeFileXLSX(wb, "export_contacts.xlsx");
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render(value, data) {
      return value || data.guest?.name;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render(value, data) {
      return value || data.guest?.email;
    },
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  // {
  //   title: "User ID",
  //   render(data){
  //     return data.guest?.id;
  //   }
  // },
  {
    title: "Group",
    render(data) {
      return data.group?.name;
    },
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "updatedAt",
  },
];

const defaultPaginate = {
  pageSize: 10,
  current: 1,
  total: 0,
};

export default function Contact() {
  const [dataContact, setDataContact] = useState([]);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [paginate, setPaginate] = useState(defaultPaginate);
  const [modal, contextHolder] = Modal.useModal();
  const [contactUpdate, setContactUpdate] = useState({});
  const [groups, setGroups] = useState([]);
  const [search, setSearch] = useState({});
  const [users, setUsers] = useState([]);
  const [allowShare, setAllowShare] = useState(false);
  const [openModalShareUser, setOpenModalShareUser] = useState(false);
  const [contactShare, setContactShare] = useState(null);

  useEffect(() => {
    getContact(paginate);
    getGroups();
    getSetting();

    const script = document.createElement("script");
    script.src = "https://sp.zalo.me/plugins/sdk.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.appendChild(script);
    };
  }, []);

  useEffect(() => {
    getContact({
      ...search,
      page: defaultPaginate.current,
      pageSize: defaultPaginate.pageSize,
    });
  }, [search]);

  const handleError = useCallback((error) => {
    console.log(error);
    modal.error({
      title:
        error.response.data?.message ??
        error.response.data?.error ??
        "Server error",
    });
  });

  const getSetting = async () => {
    try {
      const {
        data: { data },
      } = await authApi.setting();
      setAllowShare(data.ALLOW_SHARE_CONTACT);
    } catch (error) {
      handleError(error);
    }
  };

  const getContact = async (params) => {
    try {
      const {
        data: { data, pagination },
      } = await contactApi.get(params);

      setPaginate({
        ...paginate,
        current: pagination.currentPage,
        total: pagination.total,
        pageSize: pagination.pageSize,
      });

      setDataContact(data);
    } catch (error) {
      handleError(error);
    }
  };

  const getGroups = async () => {
    const {
      data: { data },
    } = await groupApi.get();
    setGroups(data);
  };

  const onSearch = (dataSearch) => {
    setSearch(dataSearch);
  };

  const renderError = (errors) => {
    return Object.keys(errors).map((i, idx) => (
      <li key={idx} style={{ padding: 0 }}>
        <p style={{ marginBottom: 0 }}>Row {i}:</p>
        <ul style={{ paddingLeft: 16 }}>
          {Object.keys(errors[i]).map((it, id) => (
            <li key={id}>{errors[i][it]}</li>
          ))}
        </ul>
      </li>
    ));
  };

  const onImport = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      await contactApi.import(formData);
      modal.success({ title: "Import contact successful." });
      getContact({
        page: defaultPaginate.current,
        pageSize: defaultPaginate.pageSize,
      });
    } catch (error) {
      const { data } = error.response;
      if (data.error) {
        modal.error({ title: data.error });
      }
      if (data.errors) {
        modal.error({
          title: "Import contact with errors:",
          content: <ul style={{ padding: 0 }}>{renderError(data.errors)}</ul>,
        });
      }
    }
  };

  const actionColumn = [
    {
      title: "Share",
      width: "100px",
      align: "center",
      render(data) {
        const link = `${window.location.origin}/contact/${data.id}`;
        return (
          <Space>
            <div
              dangerouslySetInnerHTML={{
                __html: `<div 
            class="zalo-share-button" 
            data-href="${link}" 
            data-oaid="217782902275995217" 
            data-layout="2" 
            data-customize=true>
              <button style="cursor: pointer; background: #5959db; color: white; border: 0; padding: 4px 12px; border-radius: 4px; font-size: 14px;">Zalo</button>
          </div>`,
              }}
            />
            <Button onClick={() => onOpenModalShareUser(data)}>
              <UserOutlined />
            </Button>
            <Tooltip
              placement="topLeft"
              title={"Link Copied!"}
              trigger={"click"}
            >
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(link);
                }}
              >
                <LinkOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: "Action",
      width: "100px",
      align: "center",
      render: (data) => {
        return (
          <Space>
            {!data.guest?.id && (
              <Button type="link" onClick={() => onOpenModelUpdate(data)}>
                <EditOutlined />
              </Button>
            )}
            <Button type="link" danger onClick={() => deleteContact(data)}>
              <DeleteOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  const deleteContact = (contact) => {
    modal.confirm({
      title: `Do you want delete this contact?`,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await contactApi.delete(contact.id);
          modal.success({ title: "Delete contact successful." });
          getContact({
            page: defaultPaginate.current,
            pageSize: defaultPaginate.pageSize,
          });
        } catch (error) {
          console.log({ error });
          modal.error({
            title: error.response.data.message ?? "Something error!",
          });
        }
      },
    });
  };

  const onToggleShare = async () => {
    try {
      const {
        data: { data },
      } = await authApi.allowShare({ status: !allowShare });
      setAllowShare(data.ALLOW_SHARE_CONTACT);
    } catch (error) {
      handleError(error);
    }
  };

  const onOpenModalShareUser = (contact) => {
    setContactShare(contact);
    setOpenModalShareUser(true);
  };

  const onOkModalShareUser = async (ids) => {
    setOpenModalShareUser(false);
    try {
      await contactApi.updateUsersShare(contactShare.id, { ids });
      modal.success({ title: "Update share contact for user successful." });
      getContact();
    } catch (error) {
      handleError(error);
    }
  };

  const onCancelModalShareUser = () => {
    setOpenModalShareUser(false);
  };

  const onOpenModalCreate = () => {
    setOpenModalCreate(true);
  };

  const onCreatedGroup = () => {
    setOpenModalCreate(false);
    modal.success({ title: "Create contact successful" });
    getContact({
      page: defaultPaginate.current,
      pageSize: defaultPaginate.pageSize,
    });
  };

  const onOpenModelUpdate = (contact) => {
    setOpenModalUpdate(true);
    setContactUpdate(contact);
  };

  const onUpdatedContact = () => {
    setOpenModalUpdate(false);
    setContactUpdate({});
    modal.success({ title: "Update contact successful" });
    getContact({
      page: defaultPaginate.current,
      pageSize: defaultPaginate.pageSize,
    });
  };

  const onCancelUpdate = () => {
    setOpenModalUpdate(false);
    setContactUpdate({});
  };

  const onCancelCreate = () => {
    setOpenModalCreate(false);
  };

  const changePaginate = (page, pageSize) => {
    getContact({ page, pageSize });
  };

  return (
    <AuthLayout selectKey={"contact"}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "12px",
        }}
      >
        <Typography.Title level={3}>Contact</Typography.Title>{" "}
        <Button type="primary" onClick={onOpenModalCreate}>
          <PlusOutlined /> Create
        </Button>
      </div>
      <SearchContact
        style={{ marginBottom: "16px" }}
        groups={groups}
        onSearch={onSearch}
        onImport={onImport}
        onExport={onExport}
        onToggleShare={onToggleShare}
        allowShare={allowShare}
      />
      <Table
        dataSource={dataContact}
        bordered
        columns={[...columns, ...actionColumn]}
        rowKey={"id"}
        pagination={{ ...paginate, onChange: changePaginate }}
      />
      <ModalFormContact
        open={openModalCreate}
        titleModal={"Create Contact"}
        apiSubmit={contactApi.create}
        onOk={onCreatedGroup}
        onCancel={onCancelCreate}
        groups={groups}
      />
      <ModalFormContact
        open={openModalUpdate}
        titleModal={"Update Group"}
        apiSubmit={contactApi.update}
        onOk={onUpdatedContact}
        onCancel={onCancelUpdate}
        contact={contactUpdate}
        groups={groups}
      />
      <ModalShareUser
        open={openModalShareUser}
        users={users}
        onOk={onOkModalShareUser}
        onCancel={onCancelModalShareUser}
        contactShare={contactShare}
      />
      {contextHolder}
    </AuthLayout>
  );
}
