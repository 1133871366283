import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Blank from "../../layouts/blank";
import contactApi from "../../libs/api/contact";
import { Button, Card, Col, Row } from "antd";
import Error404 from "../error/404";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  NAME_ACCESS_TOKEN,
  NAME_REDIRECT_URL_AFTER_LOGIN,
} from "../../libs/config/constant";

export default function ContactDetail() {
  const params = useParams();
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = Cookies.get(NAME_ACCESS_TOKEN);
    if (!accessToken) {
      Cookies.set(NAME_REDIRECT_URL_AFTER_LOGIN, window.location.pathname);
      navigate("/login", { replace: true });
      return;
    }
    (async () => {
      try {
        const {
          data: { data },
        } = await contactApi.detailShare(params.id);
        setContact(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <Blank />;

  if (!contact) {
    return <Error404 />;
  }

  return (
    <Blank>
      <Card
        title={`Contact by ${contact?.user?.name}`}
        bordered={false}
        style={{
          width: "calc(100vw - 40px)",
          maxWidth: "900px",
          margin: "auto",
        }}
      >
        <Row style={{ marginBottom: "12px" }}>
          <Col className="gutter-row" span={6}>
            <div>
              <b>Name:</b>
            </div>
          </Col>
          <Col className="gutter-row" span={18}>
            <div>{contact?.name}</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "12px" }}>
          <Col className="gutter-row" span={6}>
            <div>
              <b>Email:</b>
            </div>
          </Col>
          <Col className="gutter-row" span={18}>
            <div>{contact?.email}</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "12px" }}>
          <Col className="gutter-row" span={6}>
            <div>
              <b>Phone Number:</b>
            </div>
          </Col>
          <Col className="gutter-row" span={18}>
            <div>{contact?.phoneNumber}</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "12px" }}>
          <Col className="gutter-row" span={6}>
            <div>
              <b>Group:</b>
            </div>
          </Col>
          <Col className="gutter-row" span={18}>
            <div>{contact?.group?.name}</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col className="gutter-row" span={6}>
            <div>
              <b>Created At:</b>
            </div>
          </Col>
          <Col className="gutter-row" span={18}>
            <div>{contact?.createdAt}</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "0" }}>
          <Col className="gutter-row" span={6}>
            <Button type="primary" onClick={() => navigate("/")}>
              Back To Dashboard
            </Button>
          </Col>
        </Row>
      </Card>
    </Blank>
  );
}
