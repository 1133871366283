import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { NAME_ACCESS_TOKEN } from "../../libs/config/constant";
import { Link, useNavigate } from "react-router-dom";

import {
  DashboardOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  CaretDownOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Dropdown, Layout, Menu, Modal, Space, Typography, theme } from "antd";
import UserContext from "../../context/user.context";
import auth from "../../libs/api/auth";

const { Header, Content, Footer, Sider } = Layout;

const items = [
  {
    key: "dashboard",
    icon: <DashboardOutlined />,
    label: <Link to="/">Dashboard</Link>,
  },
  {
    key: "contact",
    icon: <UserOutlined />,
    label: <Link to="/contact">Contact</Link>,
  },
  {
    key: "group",
    icon: <UsergroupAddOutlined />,
    label: <Link to="/group">Group</Link>,
  },
];
const Logout = () => {
  const [, setUser] = UserContext();
  const navigate = useNavigate();

  const logoutClick = () => {
    Cookies.remove(NAME_ACCESS_TOKEN);
    setUser(null);
    navigate("/login", { replace: true });
  };

  return (
    <Space onClick={logoutClick}>
      Logout
      <LogoutOutlined />
    </Space>
  );
};

const itemsHeader = [
  {
    label: <Logout />,
    key: "0",
  },
];

const App = ({ children, selectKey }) => {
  const navigate = useNavigate();
  const [user, setUser] = UserContext();
  const [modal, contextHolder] = Modal.useModal();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    const accessToken = Cookies.get(NAME_ACCESS_TOKEN);
    if (!accessToken) {
      navigate("/login", { replace: true });
    }

    if (!user) {
      (async () => {
        try {
          const {
            data: { data },
          } = await auth.me();
          setUser(data);
        } catch (error) {
          if (error.response.status === 401) {
            Cookies.remove(NAME_ACCESS_TOKEN);
            navigate("/login", { replace: true });
          } else {
            modal.error({
              title: error.response?.data?.message ?? "Server error",
            });
          }
        }
      })();
    }
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>
      <Sider breakpoint="lg" collapsedWidth="0">
        <div
          className="demo-logo-vertical"
          style={{ marginTop: "10px", textAlign: "center" }}
        >
          <Typography.Title level={3} style={{ color: "white" }}>
            CONTACT APP
          </Typography.Title>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["dashboard"]}
          selectedKeys={selectKey ? [selectKey] : ["dashboard"]}
          items={items}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: "0 16px",
            background: colorBgContainer,
            textAlign: "right",
          }}
        >
          <Dropdown menu={{ items: itemsHeader }} trigger={["click"]}>
            <Typography.Link>
              <Space>
                {user?.name}
                <UserOutlined />
                <CaretDownOutlined />
              </Space>
            </Typography.Link>
          </Dropdown>
        </Header>
        <Content
          style={{
            margin: "24px 16px 0",
            padding: "12px",
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Demo Contact ©2023 Created by QuangLe
        </Footer>
      </Layout>
      {contextHolder}
    </Layout>
  );
};

export default App;
