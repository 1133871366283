import { Typography } from 'antd';
import Auth from '../../layouts/auth';

function Dashboard() {
  return (
    <Auth>
      <Typography.Title style={{marginTop: '40px', textAlign: 'center'}} level={2}>Welcome!</Typography.Title>
    </Auth>
  );
}

export default Dashboard;
