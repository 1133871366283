import { NAME_ACCESS_TOKEN } from "../../libs/config/constant";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";

export default function Guest({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = Cookies.get(NAME_ACCESS_TOKEN);
    if (accessToken) {
      navigate("/", { replace: true });
    }
  }, []);

  return <Layout style={{ minHeight: "100vh" }}>{children}</Layout>;
}
