import instance from "./instance";

export default {
  get: (params) => instance.get("/contact", { params }),
  create: (payload) => instance.post("/contact", payload),
  update: (id, payload) => instance.patch(`/contact/${id}`, payload),
  delete: (id) => instance.delete(`/contact/${id}`),
  import: (payload) =>
    instance.post("/contact/import", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  export: () => instance.get("/contact/export"),
  detailShare: (id) => instance.get(`/contact/${id}/share`),
  getUsersShare: (id) => instance.get(`/contact/${id}/share/users`),
  updateUsersShare: (id, payload) =>
    instance.post(`/contact/${id}/share/users`, payload),
};
