import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import contactApi from '../../../libs/api/contact'

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
];

export default function Share({ open, onOk, onCancel, contactShare }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (open) {
      getUsers();
      setSelectedRowKeys(contactShare.shareIds ?? []);
    }
  }, [open]);

  const getUsers = async () => {
    try {
      const {
        data: { data },
      } = await contactApi.getUsersShare(contactShare.id);
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const submitModal = () => {
    onOk(selectedRowKeys);
    setSelectedRowKeys([]);
  };

  const cancelModal = () => {
    onCancel();
    setSelectedRowKeys([]);
  };

  return (
    <Modal
      title="Share contact for users"
      centered
      open={open}
      width={500}
      okText={"Share"}
      onOk={submitModal}
      onCancel={cancelModal}
    >
      <Table
        rowSelection={rowSelection}
        dataSource={users}
        columns={columns}
        pagination={{ hideOnSinglePage: true }}
        rowKey={"id"}
      />
    </Modal>
  );
}
