import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import router from "./router";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { UserProvider } from "./context/user.context";

const root = ReactDOM.createRoot(document.getElementById("root"));
try {
  root.render(
    // <React.StrictMode>
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    // </React.StrictMode>
  );
} catch (error) {
  console.log({ error });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
