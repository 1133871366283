import { Form, Input, Modal, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";

const defaultValue = {
  name: "",
  email: "",
  phoneNumber: "",
  groupId: null,
};

export default function ModalFormContact({
  open,
  titleModal,
  apiSubmit,
  contact,
  onOk,
  onCancel,
  groups,
}) {
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const changeForm = () => {};

  useEffect(() => {
    if (contact && contact.id) {
      form.setFieldsValue(contact);
    }
  }, [contact]);

  const submitForm = async () => {
    setErrors({});
    setLoading(true);
    const formData = form.getFieldsValue();
    try {
      const {
        data: { data },
      } = await (contact?.id
        ? apiSubmit(contact.id, formData)
        : apiSubmit(formData));
      onOk(data);
      form.setFieldsValue(defaultValue);
    } catch (error) {
      if (error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        Modal.confirm({
          title: error.response?.data?.message ?? "Something error.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const cancelModal = () => {
    onCancel();
    setErrors({});
    form.setFieldsValue(defaultValue);
  };

  return (
    <Modal
      title={titleModal}
      centered
      open={open}
      width={800}
      okText={"Submit"}
      onOk={submitForm}
      onCancel={cancelModal}
    >
      <Form
        name={`contact-${contact?.id ?? 0}`}
        form={form}
        layout="vertical"
        initialValues={defaultValue}
        autoComplete="off"
        onChange={changeForm}
      >
        <Form.Item
          label="Name"
          name="name"
          validateStatus={errors.name ? "error" : ""}
          extra={
            errors.name && (
              <Typography.Text type="danger">{errors.name}</Typography.Text>
            )
          }
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          validateStatus={errors.email ? "error" : ""}
          extra={
            errors.email && (
              <Typography.Text type="danger">{errors.email}</Typography.Text>
            )
          }
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          validateStatus={errors.phoneNumber ? "error" : ""}
          extra={
            errors.phoneNumber && (
              <Typography.Text type="danger">
                {errors.phoneNumber}
              </Typography.Text>
            )
          }
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item label="Group" name="groupId">
          <Select>
            {groups?.length ? <Select.Option value={null}>--Choose Group--</Select.Option> : ''}
            {groups?.map((i) => (
              <Select.Option value={i.id} key={i.id}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
