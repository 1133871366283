import { useEffect, useState } from "react";
import Auth from "../../layouts/auth";
import groupApi from "../../libs/api/group";
import { Button, Modal, Space, Table, Typography } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ModalFormGroup from "../../components/modal/group/modal-form";

const columns = [
  {
    title: "#",
    width: "50px",
    align: "center",
    render(_, __, data) {
      return ++data;
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "50%",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "updatedAt",
  },
];

function Group() {
  const [dataGroup, setDataGroup] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [groupUpdate, setGroupUpdate] = useState(null);

  const getGroup = async () => {
    const {
      data: { data: groups },
    } = await groupApi.get();
    setDataGroup(groups);
  };

  useEffect(() => {
    getGroup();
  }, []);

  const onOpenModalUpdate = (group) => {
    setOpenModalUpdate(true);
    setGroupUpdate(group);
  };

  const actionColumn = {
    title: "Action",
    width: "100px",
    align: "center",
    render: (data) => {
      return (
        <Space>
          <Button type="link" onClick={() => onOpenModalUpdate(data)}>
            <EditOutlined />
          </Button>
          <Button type="link" danger onClick={() => deleteGroup(data)}>
            <DeleteOutlined />
          </Button>
        </Space>
      );
    },
  };

  const onOpenModalCreate = () => {
    setOpenModalCreate(true);
  };

  const onCreatedGroup = (group) => {
    setOpenModalCreate(false);
    getGroup();
    modal.success({ title: "Create group successful." });
  };

  const onUpdatedGroup = (group) => {
    setOpenModalUpdate(false);
    getGroup();
    modal.success({ title: "Update group successful." });
  };

  const onCancelCreate = () => {
    setOpenModalCreate(false);
  };

  const onCancelUpdate = () => {
    setOpenModalUpdate(false);
  };

  const deleteGroup = (group) => {
    modal.confirm({
      title: `Do you want delete this group?`,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await groupApi.delete(group.id);
          modal.success({ title: "Delete contact successful." });
          getGroup();
        } catch (error) {
          console.log(error);
          modal.error({
            title: error.response.error.message ?? "Something error!",
          });
        }
      },
    });
  };

  return (
    <Auth selectKey={"group"}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "12px",
        }}
      >
        <Typography.Title level={3}>Group</Typography.Title>{" "}
        <Button type="primary" onClick={onOpenModalCreate}>
          <PlusOutlined /> Create
        </Button>
      </div>
      <Table
        dataSource={dataGroup}
        bordered
        columns={[...columns, actionColumn]}
        pagination={false}
        rowKey={"id"}
      />
      <ModalFormGroup
        open={openModalCreate}
        titleModal={"Create Group"}
        apiSubmit={groupApi.create}
        onOk={onCreatedGroup}
        onCancel={onCancelCreate}
      />
      <ModalFormGroup
        open={openModalUpdate}
        titleModal={"Update Group"}
        apiSubmit={groupApi.update}
        onOk={onUpdatedGroup}
        onCancel={onCancelUpdate}
        group={groupUpdate}
      />
      {contextHolder}
    </Auth>
  );
}

export default Group;
